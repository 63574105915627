import { Component } from "react";
import * as React from "react";

import animations from "./animations";
import "./style/search.scss";
import { GET_ALL_ARTICLES_WITH_CONTENT } from "../../../../Queries";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import classNames from "classnames";

export class SearchView extends Component {

    constructor(props){
        super(props);

        this.state = {
            render: false,
            show: false,
            searchValue: "",
            searchNumber: 0,
            heightSearch: null
        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.search.isSearching !== prevState.show)
            return {
                show: nextProps.search.isSearching
            };

        return null;
    }

    animationMenu(show) {

        if(show){

            this.setState({ render: !this.state.render });

            setTimeout(() => {
                animations.onEnter(this.ref);
            }, 10);

        } else {

            animations.onLeave(this.ref);

            setTimeout(() => {
                this.setState({
                    render: !this.state.render,
                });
            }, 10);

        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    async setHeightCouverture() {

        if(typeof window !== "undefined") {
            let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

            if (iOS) {
                let heightCouverture = await import('ios-inner-height');
                let newHeight = heightCouverture.default() + "px";

                this.setState({
                    heightSearch: window.innerHeight
                });

                return true;
            }
        }

    }

    componentDidMount(){

        this.setHeightCouverture().then(() => {});

        this.setState({
            show: this.props.search.isSearching
        });

    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.search.isSearching !== this.state.show){
            this.animationMenu(this.state.show);
            this.setHeightCouverture().then(() => {});
            document.getElementsByClassName("search-view")[0].scrollTo(0, 0);
        }

    }

    searchFilterNumeros = (event, client) => {

        let articles = client.query({query: GET_ALL_ARTICLES_WITH_CONTENT, fetchPolicy: 'network-only'});
        articles.then((article) => {});

        this.setState({
            searchValue: event.target.value
        });
    };

    renderContentSurlignage(content, exp) {

        let regEx = new RegExp("(" + exp + ")(?!([^<]+)?>)", "gi");
        let replace = content.replace(regEx, "<span class='surlign'>$1</span>");

        return replace;
    }

    filterArticle(article) {

        let checkTitle = article.title.toLowerCase().includes(this.state.searchValue.toLowerCase());
        let checkDescription = article.description.toLowerCase().includes(this.state.searchValue.toLowerCase());

        let content = article.content.json;
        let checkContent = false;

        if(content) {

            checkContent = content.filter(obj => {
                return (obj.kind === "text" || obj.kind === "title")
            }).filter((content) => {
                return content.text.toLowerCase().includes(this.state.searchValue.toLowerCase())
            });

            checkContent = checkContent.length > 0;

        }

        return checkTitle || checkDescription || checkContent;

    }

    goTo = (e, link) => {
        e.preventDefault();
        this.props.toggleSearch();
        this.props.history.push(link);
    };

    renderFilter(data) {

        let articles = data.getArticles.filter((article) =>
            {
                return this.filterArticle(article)
            }
        );

        if(this.state.searchNumber !== articles.length)
            this.setState({
                searchNumber: articles.length
            });

        return articles;
    }

    render() {

        if(typeof window !== "undefined") {
            if (this.state.render) {
                requestAnimationFrame(() => {
                    document.body.style.overflowY = "hidden";
                });
            } else {
                requestAnimationFrame(() => {
                    document.body.style.overflowY = "initial";
                });
            }
        }

        const classesView = classNames({
            'toggled': this.state.render,
            'search-view': true
        });

        const classes = classNames({
            'start': !this.state.searchValue,
            'searchBox': true
        });

        const classesNumber = classNames({
            'active': this.state.searchValue,
            'results-number': true
        });


        return (
            <Query query={ GET_ALL_ARTICLES_WITH_CONTENT } fetchPolicy={'cache-only'}>
                {({loading, error, data, client}) => {

                    return (
                        <div className={ classesView } style={{ height: this.state.heightSearch }}>
                            <div className="inner" ref={(ref) => this.setReference(ref) }>
                                <div className={ classes }>
                                    <div className="inner">
                                        <span className={ classesNumber } style={ this.props.fonts.family1 }>
                                            <span className="number">
                                                {this.state.searchNumber}
                                            </span>
                                            <span> résultats de recherche pour : </span>
                                        </span>
                                        <input type="text" placeholder={"je recherche..."} style={ this.props.fonts.family1 } value={ this.state.searchValue}
                                               onChange={(e) => this.searchFilterNumeros(e, client)} />
                                    </div>
                                </div>
                                <div className="searchResults">
                                    <div className="inner">
                                        {data.getArticles && this.state.searchValue && this.renderFilter(data).map((article) => {

                                            return (
                                                <div className="article-result" key={ article._id }>
                                                    <Link onClick={(e) => this.goTo(e, `/${this.props.numero.slug}/${article.category.slug}/${article.slug}` ) } to={ `/${this.props.numero.slug}/${article.category.slug}/${article.slug}` }>
                                                        <div className="title" style={ this.props.fonts.family2 }>{article.title}</div>
                                                        <div className="description" style={ this.props.fonts.family4 }
                                                             dangerouslySetInnerHTML={ { __html: this.renderContentSurlignage(article.description, this.state.searchValue) } } />
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        )

    }

}
