import * as React from "react";

const Title = ({ fonts, datas }) => {

    if(datas.level === 1)
        return(
            <h2 className="component component-title" style={ fonts.family2 }>{ datas.text }</h2>
        );

    if(datas.level === 2)
        return(
            <h3 className="component component-title" style={ fonts.family4 }>{ datas.text }</h3>
        )
};

export default Title;
