import React from 'react';
import LateralBar from "../components/LateralBar";
import Main from "../components/Main";

import "../style/numero.scss";
import "../../Menu/style/menu.scss";

const NumeroView = ({ id, match, numero, toggleMenu, menuShow }) => {

    return (
        <div className="numero">
            <LateralBar toggleMenu={ toggleMenu } menuShow={ menuShow } />
            <Main match={ match } menuShow={ menuShow } toggleMenu={ toggleMenu } />
        </div>
    )
};

export default NumeroView;
