import { Component } from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as React from "react";

import { SettingsContext } from "../../../../../Magazine/MagazineSettings";
import classNames from "classnames";

class SearchBtn extends Component {

    shouldComponentUpdate(prevProps) {
        // return (this.props.video && prevProps.video !== this.props.video)
        return (prevProps.video !== this.props.video || prevProps.search !== this.props.search)
    }

    render() {

        return(
            <SettingsContext.Consumer>
                {({ fonts, logo, custom }) => {

                    if(this.props.video){
                        return(
                            <div className="video-action return" onClick={ this.props.videoOut } data-text="true">
                                <div className="inner">
                                    <i className="icon-arrow_left" />
                                    <span>Quitter</span>
                                </div>
                            </div>
                        )
                    }

                    if(!this.props.video){
                        return(
                            <SearchBtnView
                                history={ this.props.history }
                                slug={ this.props.slug }
                                isPage={ this.props.isPage }
                                toggleSearch={ this.props.toggleSearch } search={ this.props.search }/>
                        )
                    }

                    return null;
                }}
            </SettingsContext.Consumer>
        )
    }
}

const mapStateToProps = (state, props) => {

    return {
        video: state.video,
        search: state.search
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        videoOut: () => {
            dispatch({
                type: "VIDEO_OUT"
            })
        },
        toggleSearch: () => {
            dispatch({
                type: "TOGGLE_SEARCH"
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBtn));


class SearchBtnView extends Component {

    constructor(props){
        super(props);
    }

    toggle = () => {
        this.props.toggleSearch();
    };

    toggleHome = () => {
      this.props.history.push(this.props.slug)
    };

    render() {

        const classes = classNames({
            'searchBtn': true,
            'isSearching': this.props.search.isSearching
        });

        return(
            <div>
                {!this.props.isPage && <button className={ classes } onClick={ this.toggle } data-text="true">
                    {!this.props.search.isSearching && <i className="ico-her-search" />}
                    {this.props.search.isSearching && <i className="icon-close" />}
                </button>}
                {this.props.isPage && <button className={ classes } onClick={ this.toggleHome } data-text="true">
                    <i className="icon-close" />
                </button>}
            </div>
        );
    }


}
