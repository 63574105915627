import React, { Component } from 'react';
import { connect } from "react-redux";
import { GET_CURRENT_NUMERO } from "../../../../../Queries";
import { Query } from "react-apollo";
import Link from "react-router-dom/Link";
import classNames from 'classnames';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

class BlockChapitre extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            scrolled: false
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.isCurrent = this.isCurrent.bind(this);

    }

    findArticlesToChapitre(articles) {
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    findChapitre(numero) {
        return numero.categories.filter((category) => category.slug === this.props.match.params.chapitre);
    }

    isCurrent(article) {
        return (article.slug === this.props.match.params.slug)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll() {
        if( window.scrollY >= (539 - 90)){
            if(!this.state.scrolled)
                this.setState({ fixed: true, scrolled: true })
        } else {
            if(this.state.scrolled)
                this.setState({ fixed: false, scrolled: false })
        }
    }

    render() {

        return(
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {
                    const magazine = this.context.magazine;
                    const articles = data.currentNumero.articles;

                    let chapitre = this.findChapitre(data.currentNumero);
                    let articlesInChapitre = this.findArticlesToChapitre(articles);

                    if(articlesInChapitre)
                        return (<BlockChapitreView
                            articles={ articlesInChapitre }
                            numero={ data.currentNumero }
                            chapitre={ chapitre }
                            fixed={ this.state.fixed }
                            isCurrent={ this.isCurrent }
                            fonts={ this.props.fonts }
                        />);
                    else return null;

                }}
            </Query>
        );

    }

}

const mapStateToProps = (state, props) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

BlockChapitre.contextTypes = {
    magazine: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockChapitre));

const BlockChapitreView = ({ articles, numero, fixed, isCurrent, fonts, chapitre }) => {

    // const classes = classNames({
    //     'fixed': fixed,
    //     'inner-chapitre': true,
    // });

    const classes = classNames({
        'fixed': false,
        'inner-chapitre': true,
    });

    return(
        <section className="block-chapitre">
            <div className={ classes }>
                <header>
                    <Link to={`/${numero.slug}/${articles[0].category.slug}/${articles[0].slug}`} style={ fonts.family4 }>
                        <div className="chapitre-name" style={ fonts.family1 } data-text="true">
                                <span>
                                    { chapitre[0].title }
                                </span>
                                <i className="ico-her-question_mark" />
                        </div>
                    </Link>
                    {articles.length > 1 && <h1 data-text="true" style={ fonts.family2 }>Dans le même <br />chapitre</h1>}
                </header>
                <nav>
                    {articles.length > 1 &&
                    <ul>
                        {articles.slice(1).map((article, index) => {

                            const classes = classNames({
                                'current': isCurrent(article)
                            });

                            if(isCurrent(article)) return null;

                            return(
                                <li key={index} className={ classes } data-text="true">
                                    <Link to={`/${numero.slug}/${article.category.slug}/${article.slug}`} style={ fonts.family4 }>
                                        <span className="author" style={ fonts.family4 }>
                                            {article.author.first_name} {article.author.last_name}
                                        </span>
                                        <span className="author-work">
                                            { article.author.metier.substring(2) }
                                        </span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>}
                </nav>
            </div>
        </section>
    )
};
