import React, { Component } from 'react';
import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import BlockChapitre from "./BlockChapitre";
import Loadable from 'react-loadable';
import Text from "./components/Text";
import Title from "./components/Title";
import Citation from "./components/Citation";
import Carrousel from "./components/Carrousel";
import Image from "./components/Image";
import Chiffres from "./components/Chiffres";

import "./style/slick.scss";
import "./style/lightbox.scss";

import "../style/components.scss";

// import "slick-carousel/slick/slick.scss"
// import "slick-carousel/slick/slick-theme.scss";
// import 'react-image-lightbox/style.css';

const RightSidebarComponent = Loadable({
    loader: () => import('./RightSidebar'),
    loading: () => { return null },
});

export class Content extends Component {

    render() { const { article, match, isPage } = this.props;
        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <div className="content" style={ fonts.family4 }>

                        <div className="left-bloc">
                            {!isPage && <BlockChapitre match={ match } fonts={ fonts } />}
                        </div>

                        <div className="right-bloc">
                            {article.description &&
                                <div className="description main-description" style={ fonts.family4 }>
                                    <div data-text="true" dangerouslySetInnerHTML={{ __html: article.description }} />
                                </div>
                            }
                            <div className="content-right">
                                <div className="global-content">
                                    <div data-text="true">
                                        {article.content && article.content.map((content, index)=> {
                                            switch (content.kind) {
                                                case 'title':
                                                    return( <Title datas={ content } fonts={ fonts } key={ index } /> );
                                                case 'text':
                                                    return( <Text datas={ content } fonts={ fonts } key={ index }/> );
                                                case 'citation':
                                                    return( <Citation datas={ content } fonts={ fonts } key={ index }/> );
                                                case 'slider':
                                                    return( <Carrousel datas={ content } fonts={ fonts } key={ index }/> );
                                                case 'images':
                                                    return( <Image datas={ content } fonts={ fonts } key={ index }/> );
                                                case 'chiffres':
                                                    return( <Chiffres datas={ content } fonts={ fonts } key={ index }/> );
                                                default:
                                                    return null;
                                            }
                                        })}
                                    </div>
                                </div>
                                {!isPage &&
                                <div className="sidebar-outer">
                                    <RightSidebarComponent article={ article }/>
                                </div>
                                }
                                {/*{article.author.first_name && article.author.last_name &&*/}
                                {/*<div className="author-block">*/}
                                    {/*<div className="inner">*/}
                                        {/*<div className="avatar">*/}
                                            {/*<img src={ article.author.image } alt=""/>*/}
                                        {/*</div>*/}
                                        {/*<div className="informations">*/}
                            {/*<span className="name" style={ fonts.family1 }>*/}
                                {/*{article.author.first_name} {article.author.last_name}*/}
                            {/*</span>*/}
                                            {/*<div style={ fonts.family4 }>*/}
                                                {/*{article.author.metier &&*/}
                                                {/*<span className="metier">*/}
                                        {/*<i className="icon-avatar_author" />{ article.author.metier }*/}
                                    {/*</span>*/}
                                                {/*}*/}
                                                {/*{article.author.email &&*/}
                                                {/*<span className="email">*/}
                                        {/*<i className="icon-mail_author" />{article.author.email}*/}
                                    {/*</span>*/}
                                                {/*}*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                )}
            </SettingsContext.Consumer>
        )
    }

}
