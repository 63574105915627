import { Component } from "react";
import * as React from "react";

let ChiffreSlider;

class Chiffres extends Component {

    constructor(props){
        super(props);
        this.state = {
           isLoaded: false
        }
    }

    componentDidMount() {
        import('react-slick').then((module) => {
            ChiffreSlider = module.default;
            this.setState({
                isLoaded: true
            });
        });
    }

    render() {
        let { fonts, datas } = this.props;
        const settings = {
            dots: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            className: "center",
            centerPadding: "100px",
            speed: 200,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerPadding: "80px",
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        centerPadding: "30px",
                        draggable: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerPadding: "30px",
                        draggable: true
                    }
                }
            ]
        };

        // return (
        //     <div className="component component-chiffres">
        //         <ul>
        //             {datas.chiffres.map((chiffre, index) => (
        //                 <li className="content-chiffre" key={ index }>
        //                     { chiffre.chiffre } { chiffre.legende }
        //                 </li>
        //             ))}
        //         </ul>
        //     </div>
        // );

        if(this.state.isLoaded) {
            return (
                <div className="component component-chiffres">
                    <ChiffreSlider {...settings}>
                        {datas.chiffres.map((chiffre, index) => (
                            <div className="content-chiffre" key={ index } style={{ width: 100 }}>
                                <div className="chiffre" style={ fonts.family1 }>
                                    { chiffre.chiffre }
                                </div>
                                <div className="legende" style={ fonts.family4 }>
                                    { chiffre.legende }
                                </div>
                            </div>
                        ))}
                    </ChiffreSlider>
                </div>
            );
        } else {
            return (
                <div className="component component-chiffres">
                    {datas.chiffres.map((chiffre, index) => (
                        <div className="content-chiffre" key={ index } style={{ width: 100 }}>
                            <div className="chiffre" style={ fonts.family1 }>
                                { chiffre.chiffre }
                            </div>
                            <div className="legende" style={ fonts.family4 }>
                                { chiffre.legende }
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

    }

}

export default Chiffres;
