import React, { Component } from 'react';
import {GET_ALL, GET_ARTICLE, GET_CURRENT_NUMERO, GET_PREVIEW_ARTICLE} from "../../../../Queries";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { ArticleContentView } from "./ArticleContentView";

class ArticleContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            getPreviewArticle: null,
            articles: null,
            currentArticle: null,
            previewLoading: true
        }

    }

    getArticleToArticles(articles) {
        return articles.filter((article) => article.slug === this.props.match.params.slug)[0];
    }

    getArticleWithQuery(articles, numero) {

        let article = articles.filter((article) => article.slug === this.props.match.params.slug)[0];

        if(!article) {
            this.props.history.push("/" + numero.slug);
        }

        return article;

    }

    isPreview() {
        return this.props.location.search;
    }

    componentDidMount() { let that = this;
        if(this.isPreview()) {
            setTimeout(function() {
                that.getPreviewArticle();
            }, 10);
        }
    }

    getPreviewArticle() { let that = this;

        const data = this.props.client.readQuery(
            { query: GET_CURRENT_NUMERO });

        if(this.state.currentArticle){
            let article = this.props.client.query(
                { query: GET_PREVIEW_ARTICLE, ssr: false, variables: {
                        magazine_slug : this.context.magazine.slug,
                        article_id: this.state.currentArticle._id
                    }}
            );

            article.then(article => {
                if(article.data.getPreviewArticle.timeForRead) {
                    this.setState({
                        currentArticle: article.data.getPreviewArticle
                    });
                    that.updatePreviewArticle(article.data.getPreviewArticle, data.currentNumero.articles);
                }
            });

        }

    }

    setCurrentArticle(currentArticle) {
        if(!this.state.currentArticle){
            this.setState({
                currentArticle
            })
        }
    }

    updatePreviewArticle(preview, articles) {

        const data = this.props.client.readQuery(
            {
                query: GET_ALL,
                variables: {
                    magazine_slug: this.context.magazine.slug,
                    offset:0
                }
            });

        let currentArticle = this.getArticleToArticles(articles);

        for(let numero in data.datas.numeros) {
            let articles = data.datas.numeros[numero].articles;

            for(let article in articles){
                if(articles[article]._id === currentArticle._id){

                    let articleObj = articles[article];

                    articleObj.title = preview.title;
                    articleObj.author = preview.author;
                    articleObj.wallpaper = preview.wallpaper;
                    articleObj.timeForRead = preview.timeForRead;

                }
            }
        }

        this.props.client.writeQuery({
            query: GET_ALL,
            variables: {
                magazine_slug: this.context.magazine.slug,
                offset: 0
            },
            data
        });

        this.setState({
            previewLoading: false,
        });

        this.props.previewAvailable();

    }

    render() { let that = this;

        /**if(this.isPreview()) {

            return(
                <Query query={ GET_CURRENT_NUMERO }>
                    {({loading, error, data, client, networkStatus}) => {

                        if (loading) return null;
                        if (error) return `Error! ${error.message}`;

                        let articles = data.currentNumero.articles;
                        let article = this.getArticleWithQuery(data.currentNumero.articles, data.currentNumero);

                        return(
                            <Query query={ GET_PREVIEW_ARTICLE }
                                   variables={{ magazine_slug: this.context.magazine.slug, article_id: article._id }} fetchPolicy="cache-first">
                                {({ loading, error, data, networkStatus }) => {

                                    if (loading) return null;
                                    if (error) return `Error! ${error.message}`;

                                    if(data.getPreviewArticle) {

                                        //this.updatePreviewArticle(data.getPreviewArticle, articles);
                                        if(this.state.getPreviewArticle !== data.getPreviewArticle) {

                                            if(typeof window !== "undefined"){
                                                persist.purge();
                                            }

                                            this.setState({
                                                getPreviewArticle: data.getPreviewArticle,
                                                articles: articles
                                            });
                                        }

                                        return (
                                            <ArticleContentView
                                                article={ data.getPreviewArticle }
                                                numero={ data.currentNumero } fonts={ this.props.fonts }/>
                                        );
                                    } return null;

                                }}
                            </Query>
                        )
                    }}
                </Query>
            )


        }*/

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client, networkStatus}) => {

                    if (loading) return null;
                    if (error) return `Error! ${error.message}`;

                    let article = this.getArticleWithQuery(data.currentNumero.articles, data.currentNumero);

                    return(
                        <Query query={ GET_ARTICLE }
                               variables={{ magazine_slug: this.context.magazine.slug, article_id: article._id }} fetchPolicy="cache-first">
                            {({ loading, error, data, networkStatus }) => {

                                if (loading) return "";
                                if (error) return `Error! ${error.message}`;

                                if(data.getArticle) {
                                    that.setCurrentArticle(data.getArticle);

                                    if(this.isPreview()) {
                                        if (this.props.previewLoading) {
                                            return null
                                        }
                                    }

                                    return (
                                        <ArticleContentView
                                            article={ data.getArticle }
                                            numero={ data.currentNumero } fonts={this.props.fonts}
                                            match={ this.props.match }
                                        />
                                    );
                                } return null;

                            }}
                        </Query>
                    )
                }}
            </Query>
        )
    }

}

ArticleContent.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        params: props.match.params,
        previewLoading: state.preview.previewLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        previewAvailable: () => {
            return dispatch({
                type: 'PREVIEW_AVAILABLE',
            })
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ArticleContent)));
