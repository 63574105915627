import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Query, withApollo } from "react-apollo";
import { GET_ALL, GET_CURRENT_NUMERO } from "../../../../Queries";

import { SettingsContext } from "../../../Magazine/MagazineSettings";
import { CibleView } from "./views/CibleView";

class Cible extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
           currentArticle: null,
           warnings : [
                "Veuillez ajouter un article à la première catégorie",
                "Veuillez ajouter des catégories à la publication"
           ],
        }

    }

    changeCurrentArticle(article) {

        if(this.state.currentArticle){
            if(this.state.currentArticle._id !== article._id){
                this.setState({
                    currentArticle: article,
                });
            }
        } else {
            this.setState({
                currentArticle: article,
            });
        }

    }

    getCurrentArticle(articles, slug){
        return articles.filter((article) => article.slug === slug);
    }

    getFirstArticleToRead(data) {

        const categoriesArr = data.currentNumero.categories.slice(0);

        if(categoriesArr.length === 0) {
            return {
                error: true,
                warning: this.state.warnings[1]
            }
        }

        const category = categoriesArr.sort((a, b) => {
            return (a.order - b.order)
        })[0];

        let article = data.currentNumero.articles.find((article) => article.category._id === category._id);

        if(!article) {
            return {
                error: true,
                warning: this.state.warnings[0]
            }
        } else {
            return {
                error: false,
                article
            }
        }

    }



    getArticleCouv(data) {

        const articles = data.currentNumero.articles;
        const couverture = data.currentNumero.couverture;
        let firstArticle = {};
        let others = [];

        couverture.forEach((articleId, index) => {
            if(index === 0){
                firstArticle = articles.find(( article) => article._id === articleId);
            }
            else others.push(articles.find(( article) => article._id === articleId));
        });

        return {
            firstArticle,
            others
        }

    }

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.videoOut();
        this.props.captureLogoClick();
        this.props.history.push(link);
    };

    render() { let articleFinal, articleView, couvertureView, view;

        if(this.props.match.url === "/credits" || this.props.match.url === "/mentions-legales") return null;

        return(
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }} fetchPolicy="cache-first">
                {({loading, error, data, updateQuery, client, networkStatus}) => {

                    let numeros = data.datas.numeros;

                    return(
                        <Query query={ GET_CURRENT_NUMERO }>
                            {({loading, error, data, client}) => {

                                if (loading) { this.forceUpdate(); return null }
                                if (error) return `Error! ${error.message}`;

                                let articles = data.currentNumero.articles;

                                if(!articles) return <div className='warning'> { this.state.warnings[0] } </div>;

                                let firstRead = this.getFirstArticleToRead(data);
                                let article = firstRead.article;

                                if(this.props.match.params.chapitre) {
                                    articleView = true; couvertureView = false;
                                    view = "article";
                                    article = this.getCurrentArticle(articles, this.props.match.params.slug)[0];
                                } else {
                                    articleView = false; couvertureView = true;
                                    view = "couverture";
                                }

                                if(!article) return null;

                                if(!firstRead.error)
                                    return (
                                        <SettingsContext.Consumer>
                                            {({ fonts, logo }) => {

                                                const classes = classNames({
                                                    'article-view': articleView,
                                                    'couverture-view': couvertureView,
                                                    'cible-view frame': true
                                                });

                                                let currentNumero = data.currentNumero;

                                                return(
                                                    <CibleView
                                                        magazine={ this.context.magazine }
                                                        numero={ currentNumero }
                                                        numeros={ numeros }
                                                        history={ this.props.history }
                                                        show={ this.props.show }
                                                        client={ this.props.client }
                                                        openLink={ this.openLink }
                                                        scrolled={ this.props.scrolled }
                                                        isSearching={ this.props.search }
                                                        user={ this.props.user }
                                                        videoIn={ this.props.videoIn }
                                                        logo={ logo }
                                                        video={ this.props.video }
                                                        toggleMenu={ this.props.toggleMenu }
                                                        getToLastRead={ this.getToLastRead }
                                                        article={ article }
                                                        startRead={ this.props.startRead }
                                                        continueRead={ this.props.continueRead }
                                                        unsetArticleReaded={ this.props.unsetArticleReaded }
                                                        setArticleReaded={ this.props.setArticleReaded }
                                                        captureCouvRead={ this.props.captureCouvRead }
                                                        fonts={ fonts }
                                                        classes={ classes }
                                                        view={ view }
                                                    />
                                                )

                                            }}
                                        </SettingsContext.Consumer>
                                    );
                                else
                                    return <div className='warning'> { firstRead.warning } </div>
                            }}
                        </Query>
                    )

                }}
            </Query>
        )

    }

}

Cible.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        currentArticle: state.currentArticle,
        user: state.user,
        scrolled: state.scrolled.scrolled,
        video: state.video,
        search: state.search.isSearching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startRead: () => {
            dispatch({
                type: 'COMMENCER_LECTURE'
            });
        },
        continueRead: () => {
            dispatch({
                type: 'REPRENDRE_LECTURE'
            });
        },
        captureCouvRead: () => {
            dispatch({
                type: 'CLICK_LECTURE_UNE'
            });
        },
        setArticleReaded: (article) => {
            dispatch({
                type: 'USER_SET_ARTICLE_READ',
                article,
            });
        },
        unsetArticleReaded: (article) => {
            dispatch({
                type: 'USER_UNSET_ARTICLE_READ',
                article,
            });
        },
        captureLogoClick: (value) => {
            dispatch({
                type: "CLICK_LOGO"
            })
        },
        videoIn: () => {
            dispatch({
                type: 'VIDEO_IN'
            });
        },
        videoOut: () => {
            dispatch({
                type: 'VIDEO_OUT'
            });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Cible)));
