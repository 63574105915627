import * as React from "react";
import CenterMode from "./CenterMode";

const Carrousel = ({ fonts, datas }) => {
    return(
        <div className="component component-carrousel">
            <CenterMode datas={ datas }/>
        </div>
    )
};

export default Carrousel;
