import { Component } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {Query, withApollo} from "react-apollo";
import { GET_ALL, GET_CURRENT_NUMERO } from "../../../../Queries";
import { SearchView } from "./SearchView";
import PropTypes from "prop-types";

import { SettingsContext } from "../../../Magazine/MagazineSettings";

class Search extends Component {

    render() {
        return (
            <SettingsContext.Consumer>
                {({ fonts }) => {
                    return (
                        <Query query={GET_ALL} variables={
                            {
                                magazine_slug: this.context.magazine.slug,
                                offset: 0
                            }
                        } fetchPolicy="cache-first">
                            {({loading, error, data, fetchMore}) => {
                                let datas = data.datas;

                                return (
                                    <Query query={GET_CURRENT_NUMERO}>
                                        {({loading, error, data, client}) => {
                                            return (
                                                <SearchView search={this.props.search}
                                                            numero={data.currentNumero}
                                                            fonts={ fonts }
                                                            toggleSearch={this.props.toggleSearch}
                                                            match={this.props.match}
                                                            history={this.props.history}
                                                            toggleMenu={this.props.toggleMenu}
                                                            data={datas}
                                                />
                                            )
                                        }}
                                    </Query>
                                )

                            }}
                        </Query>
                    );

                }}
            </SettingsContext.Consumer>
        )
    }

}

const mapStateToProps = (state, props) => {

    return {
        search: state.search
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSearch: () => {
            dispatch({
                type: "TOGGLE_SEARCH"
            })
        }
    };
};

Search.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Search)));
