import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function truncate(string, length){
    if (string.length > length)
        return string.substring(0 , length) +'...';
    else
        return string;
}

class MetasArticle extends Component {

    constructor(props){
        super(props);

        this.metas = {
            meta_description: "",
            meta_descrition_fb: "",
            meta_image_fb: "",
            meta_title_fb: "",
            meta_title: "",
            meta_description_twitter: "",
            meta_image_twitter: "",
            meta_title_twitter: ""
        };

    }

    render() {

        this.metas.meta_description = this.props.article.metas.meta_description;

        if(this.props.article.metas.meta_descrition_fb !== "") {
            this.metas.meta_description_fb = this.props.article.metas.meta_descrition_fb;
        } else {
            this.metas.meta_description_fb = this.props.article.metas.meta_description;
        }

        if(this.props.article.metas.meta_image_fb !== "") {
            this.metas.meta_image_fb = this.props.article.metas.meta_image_fb
        } else {

            if(!this.props.isPage){
                this.metas.meta_image_fb = this.props.article.wallpaper.url;
            } else {
                this.metas.meta_image_twitter = "";
            }

        }

        if(this.props.article.metas.meta_title_fb !== "") {
            this.metas.meta_title_fb = this.props.article.metas.meta_title_fb;
        } else {
            this.metas.meta_title_fb = this.props.article.title + " - " + this.props.magazine.title;
        }

        if(this.props.article.metas.meta_title !== "") {
            this.metas.meta_title = this.props.article.metas.meta_title;
        } else {
            this.metas.meta_title = this.props.article.title + " - " + this.props.magazine.title;
        }

        if(this.props.article.metas.meta_description_twitter !== "") {
            this.metas.meta_description_twitter = this.props.article.metas.meta_description_twitter;
        } else {
            this.metas.meta_description_twitter = this.props.article.metas.meta_description;
        }

        if(this.props.article.metas.meta_image_twitter !== "") {
            this.metas.meta_image_twitter = this.props.article.metas.meta_image_twitter;
        } else {
            if(!this.props.isPage){
                this.metas.meta_image_twitter = this.props.article.wallpaper.url;
            } else {
                this.metas.meta_image_twitter = "";
            }
        }

        if(this.props.article.metas.meta_title_twitter !== "") {
            this.metas.meta_title_twitter = this.props.article.metas.meta_title_twitter;
        } else {
            this.metas.meta_title_twitter = this.props.article.title + " - " + this.props.magazine.title;
        }

        return(
            <Helmet>

                <meta charSet="utf-8" />
                <title>{ this.metas.meta_title }</title>
                <link rel="canonical" href={ "https://" + this.props.magazine.slug + this.props.location.pathname + "/"} />
                <meta name="description" content={ this.metas.meta_description } />

                <meta property="og:type" content="article" />
                <meta property="og:url" content={ "https://" + this.props.magazine.slug + this.props.location.pathname + "/"} />
                <meta property="og:title" content={ this.metas.meta_title_fb }/>
                <meta property="og:description" content={ this.metas.meta_description_fb } />
                <meta property="og:image" content={ this.metas.meta_image_fb } />

                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:description" content={ this.metas.meta_description_twitter } />
                <meta name="twitter:title" content={ this.metas.meta_title_twitter } />
                <meta name="twitter:image" content={ this.metas.meta_image_twitter } />

            </Helmet>
        )

    }

}

MetasArticle.contextTypes = {
    magazine: PropTypes.object,
};


const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(MetasArticle)));
