import React, { Component, PureComponent } from 'react';

import animations from "../animations";

export default class AnimBackground2 extends PureComponent {

    constructor(props) {
        super(props);
        this.ref = "";

        this.state = {
            show: false,
        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.show !== prevState.show)
            return {
                show: nextProps.show
            };

        return null;
    }

    componentDidMount() {
       // animations.backgroundEnter(this.ref);

        this.setState({
            show: this.props.show,
        });

    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.show !== this.state.show){
            if(this.state.show){
                animations.backgroundEnter(this.ref);
            } else {
                animations.backgroundLeave(this.ref);
            }
        }
    }

    render() {
        return (
            <div className="background-menu" ref={ this.setReference } />

            // <svg height="100%" width="100%">
            //     <circle cx="0%" cy="0%" r="0%" stroke="black" strokeWidth="0" fill="#4E49FC" ref={ this.setReference } />
            // </svg>
        )
    }
}
